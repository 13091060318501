<template>
  <ion-page>
    <ion-header class="header-desktop-maps">
      <ion-toolbar>
        <h3 style="margin: 16px;">
          Панель администратора | Маршруты
        </h3>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <YandexRouteContainer is-admin />
    </ion-content>
  </ion-page>
</template>

<script>
import YandexRouteContainer from "../../Tabs/Routes/YandexRouteContainer";
export default {
  name: "Index",
  components: {YandexRouteContainer}
}
</script>

<style scoped>

</style>